import React from "react";
import Logo from "./logo.png";
import "./App.css";
import { motion } from "framer-motion";

const App = () => {
  return (
    <div className="App">
      <div className="AppContainer">
        <motion.img
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={Logo}
        />
        <motion.div
          animate={{ opacity: 1 }}
          className="AppContainerFlex"
          transition={{ duration: 1 }}
        >
          <h1 className="AppTitle">CloudSoft</h1>
          <p className="AppParagraph">
            Innovative services and software solutions
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default App;
